import React from 'react';
import logo from '../assets/logo.png';

const WelcomeScreen = () => {
  return (
    <>
      <div className='welcome_screen'>
        <img src={logo} alt="logo" width={"20%"} />
      </div>
    </>
  )
}

export default WelcomeScreen
