import * as actions from "../actionTypes";

const initialState = {
    token: '',
    isLoggedIn: false,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case actions.LOGIN_SUCCESS: return {
            ...state,
            ...payload,
            isLoggedIn: true
        }

        case actions.FORGOT_SUCCESS: return {
            ...state,
            ...payload,
            isLoggedIn: true
        }

        case actions.RESET_SUCCESS: return {
            ...state,
            ...payload,
            isLoggedIn: true
        }

        default: return state
    }
} 