export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// VIDEOS TYPES
export const GET_ADMIN_VIDEOS = "GET_ADMIN_VIDEOS";
export const ADD_VIDEOS = "ADD_VIDEOS";
export const UPDATE_VIDEOS = "UPDATE_VIDEOS";
export const DELETE_VIDEOS = "DELETE_VIDEOS";
